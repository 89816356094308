/****************Page scroll slide animation for all pages ********************/
(function ($) {
    $.fn.visible = function (partial) {
        var $t = $(this),
            $w = $(window),
            viewTop = $w.scrollTop(),
            viewBottom = viewTop + $w.height(),
            _top = $t.offset().top,
            _bottom = _top + $t.height(),
            compareTop = partial === true ? _bottom : _top,
            compareBottom = partial === true ? _top : _bottom;

        return ((compareBottom <= viewBottom) && (compareTop >= viewTop));
    };
})(jQuery);

var win = $(window);

var allMods = $(".sec-slide-effect");

allMods.each(function (i, el) {
    var el = $(el);
    if (el.visible(true)) {
        el.addClass("already-visible");
    }
});

win.scroll(function (event) {
    allMods.each(function (i, el) {
        var el = $(el);
        if (el.visible(true)) {
            el.addClass("come-in");
        }
    });
});
/**************** /Page scroll slide animation for all pages ********************/

function scrollTop() {
    $('html, body').animate({ scrollTop: 0 }, 'slow');
}



/*****************Only Number input function */
function isNumber(evt) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }
    return true;
}

/************************ value should be INR Formate/comma sepreted ***********/
function inrFormat(nStr) { // nStr is the input string
    var nStr1 = nStr.value;
    nStr1 = nStr1.split(',').join('');
    nStr1 += '';
    x = nStr1.split('.');
    x1 = x[0];
    x2 = x.length > 1 ? '.' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    var z = 0;
    var len = String(x1).length;
    var num = parseInt((len / 2) - 1);

    while (rgx.test(x1)) {
        if (z > 0) {
            x1 = x1.replace(rgx, '$1' + ',' + '$2');
        }
        else {
            x1 = x1.replace(rgx, '$1' + ',' + '$2');
            rgx = /(\d+)(\d{2})/;
        }
        z++;
        num--;
        if (num == 0) {
            break;
        }
    }
    nStr.value = x1 + x2;
    return x1 + x2;
}

/*****************Only Percent input function */
function isPercent(evt) {
    var val = evt.value;
    var valLength = val.length;
    if (valLength == 1 && val == 0) {
        evt.value = val.slice(0, -1)
    }
    if (valLength == 2 && val == 00) {
        evt.value = val.slice(0, -2)
    }
    if (valLength == 3 && val == 000) {
        evt.value = val.slice(0, -3)
    }
    if (val > 99) {
        evt.value = val.slice(0, -1)
    }
}

function returnScroll(evt) {
    $("#" + evt).stop();
    $("#" + evt).addClass("ellipsis");
    $("#" + evt).animate({
        scrollLeft: 0
    }, 'slow');
};

function scrollText(evt) {
    $("#" + evt).removeClass("ellipsis");
    var maxscroll = $("#" + evt).width();
    var speed = maxscroll * 15;
    $("#" + evt).animate({
        scrollLeft: maxscroll
    }, speed, "linear");
};

function lettersOnly() {
    var charCode = event.keyCode;
    if ((charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123) || charCode == 8 || charCode == 32)
        return true;
    else
        return false;
}

var startwidth;
$(document).ready(function () {

    setTimeout(function () {
        resizelist = true;
        startwidth = $(window).width();
    }, 1000);

});

/*****************If E-mail not verified *************/
function emailNonMandatory(evt) {

    var getid = evt.id;
    var val = evt.value;
    var ttlLength = val.length;
    var email_pattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    if (ttlLength > 0) {
        if (!email_pattern.test(val)) {
            $("#" + getid + "_1").css("display", "block");
            $("#" + getid).css("border-color", "#a94442");
        }
        else {
            $("#" + getid + "_1").css("display", "none");
            $("#" + getid).css("border-color", "#dbdcdc");
        }
    }
    else {
        $("#" + getid + "_1").css("display", "none");
        $("#" + getid).css("border-color", "#dbdcdc");
    }
}

function makeAddressInvalid(obj) {
    $(obj).val(0);
}


/******************Registration page Accordian menu ***********/
$(document).ready(function () {

    function close_accordion_location() {
        $('.faq-accordian .accordian-arrow').removeClass('active');
        $('.faq-accordian  .faq-ans').slideUp(300).removeClass('open');
    }

    $(document).on('click', '.faq-accordian .accordian-arrow', function (e) {

        if ($(this).hasClass('active')) {
            close_accordion_location();
        } else {
            close_accordion_location();
            $(this).addClass('active');
            $(this).next('div').slideDown(300).addClass('open');
        }
        e.preventDefault();
    });

});


/******************Clone div function for Register Form ***********/

idInc = 0;
idadd = 0;
idRm = 0;

function cloneDiv(parent) {
    var numItems = $(parent + ' .entry').length;
    idInc = numItems - 1;
    idadd = idInc;
    idRm = idInc;
    if (numItems < 5) {
        cloned = $('#clone' + idInc);
        $("#clone0").clone().attr('id', 'clone' + (++idInc)).appendTo(parent).hide();
        $("#clone" + idInc).find("input[type=text], select").val("");
        $("#clone" + idInc).slideDown("fast");
        var currId = $("#clone" + idInc);
        $(currId).find("#rem0").attr('id', 'rem' + (++idadd));
        $(currId).find("#add0").attr('id', 'add' + (++idRm));
        $(currId).find("#add" + idInc).hide();
        $(currId).find("#rem" + idInc).css('display', 'table');
    }
    if (numItems == 5) {
        $('.cloneadd').addClass("disabled");
    }
}


function cloneDivFund(parent) {
    var numItems = $(parent + ' .entry').length;
    idInc = numItems - 1;
    idadd = idInc;
    idRm = idInc;
    if (numItems < 5) {
        // cloned = $('#clone' + idInc);
        $("#clone0_fund").clone().attr('id', 'clone' + (++idInc)).appendTo(parent).hide();
        $("#clone" + idInc).find("input[type=text], select").val("");
        $("#clone" + idInc).slideDown("fast");
        var currId = $("#clone" + idInc);
        $(currId).find("#fundrem0").attr('id', 'rem' + (++idadd));
        $(currId).find("#fundadd0").attr('id', 'add' + (++idRm));
        $(currId).find("#add" + idInc).hide();
        $(currId).find("#rem" + idInc).css('display', 'table');
    }
    if (numItems == 5) {
        $('.cloneaddfund').addClass("disabled");
    }
}

function removeFormdiv(id) {
    var remdiv = id.substr(3, id.length - 3);
    var newid = "#clone" + remdiv;
    $(newid).slideUp("fast", function () {
        $(this).remove();
    });
    var numItems = $('.entry').length;
    if (numItems > 1) {
        $('.cloneadd').removeClass("disabled");
    }
}

var removeselect = false;
var removeid = 0;
var id_Inc = 0;
var id_add = 0;
var id_Rm = 0;
var cloned1;

function cloneDiv1(parent) {
    var numItems1 = $('#count_clone1 .entry').length;
    id_Inc = numItems1;
    id_add = numItems1;
    if (numItems1 <= 3) {
        cloned1 = $('#clone_' + id_Inc);
        //var value = $("#clone_"+id_Inc+" "+"#roundFunding option:selected").val();

        if (removeselect == true) {
            var place = removeid - 1;
            id_Inc = place;
            $("#clone_0").clone().attr('id', 'clone_' + (++id_Inc)).insertAfter('.box_container1' + ' ' + '#clone_' + place).hide();
            $("#clone_" + id_Inc).find("input[type=text]").val("");
            $("#clone_" + id_Inc).slideDown("fast");     //alert("valCome-" + value);
            $("#clone_" + id_Inc + " " + "#roundFunding option").prop('disabled', true);
            $("#clone_" + id_Inc + " " + "#roundFunding #rase_" + removeid).prop('disabled', false);
            removeselect = false;
        }
        else {
            $("#clone_0").clone().attr('id', 'clone_' + (++id_Inc)).appendTo('.box_container1').hide();
            $("#clone_" + id_Inc).find("input[type=text]").val("");
            $("#clone_" + id_Inc).slideDown("fast");     //alert("valCome-" + value);
            $("#clone_" + id_Inc + " " + "#roundFunding option").prop('disabled', true);
            $("#clone_" + id_Inc + " " + "#roundFunding #rase_" + id_Inc).prop('disabled', false);
        }
        $('#clone_' + id_Inc + ' select').removeAttr('disabled');

        var currId1 = $("#clone_" + id_Inc);
        var btnval = id_Inc - 1;
        id_add = btnval;
        id_Rm = btnval;
        //console.log("id_add-before-" + id_add);
        $(currId1).find("#rem_0").attr('id', 'rem_' + (++id_add));
        //console.log("id_add-after-" + id_add);
        $(currId1).find("#add_0").attr('id', 'add_' + (++id_Rm));
        $(currId1).find("#add_" + id_Inc).hide();
        $(currId1).find("#rem_" + id_Inc).css('display', 'table');
    }
    if (numItems1 == 3) {
        $('.clone_add').addClass("disabled");
    }
};

function removeFormdiv1(id) {
    var rem_div = id.substr(4, id.length - 4);
    var new_id = "#clone_" + rem_div;
    $(new_id).slideUp("fast", function () {
        $(this).remove();
    });
    var numItems1 = $('#count_clone1 .entry').length;
    //console.log(numItems);
    if (numItems1 == 4) {
        $('.clone_add').removeClass("disabled");
    }
    removeselect = true;
    removeid = rem_div;
    //alert(rem_div);
}

/******************Business Registration Pages ******************/
/****************Other Details Looking for*******************/
$(document).on('click', '.other-detail input[type="checkbox"]', function () {
    if ($(this).attr("name") == "seeking_investors") {
        $(".invester").slideToggle();
    }
    if ($(this).attr("name") == "seeking_loan") {
        $(".loansseeking").slideToggle();
    }
    if ($(this).attr("name") == "seeking_accelerators") {
        $(".incubatorsaccelerator").slideToggle();
    }
    if ($(this).attr("name") == "seeking_buyers") {
        $(".businesssale").slideToggle();
    }
    if ($(this).attr("name") == "seeking_mentors") {
        $(".mentorsearch").slideToggle();
    }
    if ($(this).attr("name") == "seeking_incubators") {
        $(".incubatorsaccelerator").slideToggle();
    }
    if ($(this).attr("name") == "seeking_acquirers") {
        $(".businesssale").slideToggle();
    }
    if ($(this).attr("name") == "seeking_mentorship") {
        $(".mentorsearch").slideToggle();
    }
});

/************** My Account accordian ******************************/
var currentAttrValue;

function close_accordion_myAccount() {
    $('.my-account-dropdown .accordian-arrow').removeClass('active');
    $('.my-account-dropdown .intraction-list').slideUp(300).removeClass('open');
}

/**  listing tab  **/

function myAccountAccordian(id) {
    currentAttrValue = id;
    if ($("." + currentAttrValue).hasClass('active')) {
        close_accordion_myAccount();
    } else {
        close_accordion_myAccount();
        $("." + currentAttrValue).addClass('active');
        $('.' + currentAttrValue + "_1").slideDown(300).addClass('open');
    }

};

function setPos(getid, id) {
    $(".tabScroll li a").removeClass("active");
    $("#" + id).addClass("active");
    var pageScroll = $("#" + getid).offset().top - 150;
    var body = $("html, body");
    body.stop().animate({ scrollTop: pageScroll }, 500, 'swing', function () {
    });
}

$(window).scroll(function () {
    if ($(this).scrollTop() > 1) {
        $('#landfix').addClass("stickylandp");
        $('#tst').addClass("topshadow");
    }
    else {
        $('#landfix').removeClass("stickylandp");
    }
});

$(document).on('scroll', function () {

    scrollTop = $(this).scrollTop();
    if ($("#businesssaleoverview").offset() !== undefined) {
        businessoverview = $("#businesssaleoverview").offset().top - 190;
        businesssaledetails = $("#businesssaledetails").offset().top - 190;
        businesssalefinancials = $("#businesssalefinancials").offset().top - 190;
        businesssalerequirement = $("#businesssalerequirement").offset().top - 190;

        if (scrollTop >= 110 && scrollTop < businesssaledetails) {
            $(".tabScroll li a").removeClass("active");
            $("#businesssaleoverview_tab").addClass("active");
        }
        if (scrollTop >= businesssaledetails && scrollTop < businesssalefinancials) {
            $(".tabScroll li a").removeClass("active");
            $("#businesssaledetails_tab").addClass("active");
        }

        if (scrollTop >= businesssalefinancials && scrollTop < businesssalerequirement) {
            $(".tabScroll li a").removeClass("active");
            $("#startupfinancials_tab").addClass("active");
        }
        if (scrollTop >= businesssalerequirement) {
            $(".tabScroll li a").removeClass("active");
            $("#startuprequirement_tab").addClass("active");
        }
    }

});

/** listing tab */

// function frg_panel() {
//     $("#LoginBlock").hide();
//     $("#ForgotBlock").show();
// }

// function login_panel() {
//     $("#LoginBlock").show();
//     $("#ForgotBlock").hide();
// }


function forgot_panel() {
    $('.forgotblk').show()
    $('.loginblk').hide()
}

function login_panel() {
    $('.forgotblk').hide()
    $('.loginblk').show()
}


function openNav() {
    document.getElementById("mySidenav").style.width = "290px";
}

function closeNav() {
    document.getElementById("mySidenav").style.width = "0";
}

function commentsec() {
    $('html, body').animate({
        scrollTop: $('#commentpost').offset().top
    }, 1000);
}


function shrink(id) {
    if (id == "more") {
        $("#shrinkMe").css("height", "auto");
        $("#more").css("display", "none");
        $("#less").css("display", "block");
    } else {
        $("#shrinkMe").css("height", "89px");
        $("#more").css("display", "block");
        $("#less").css("display", "none");
    }
}